<template>
    <div>
        <p>{{environment}} —— {{system}}</p>
        <van-field
            v-model="message"
            rows="1"
            autosize
            clearable
            label="文章地址"
            type="textarea"
            placeholder="文章地址粘贴到此处"
        />
        <div style="margin: 16px;" class="test-button-area">
            <van-button round block type="info" @click="saveToStorage">保存地址到缓存</van-button>
            <p>跳转方法：</p>
            <van-button round block @click="toUrl(1)">传统跳转</van-button>
            <van-button round block @click="toUrl(2)">随机参数跳转</van-button>
            <van-button round block @click="toUrl(3)">随机路由跳转</van-button>
            <van-button round block @click="toUrl(4)">随机路由+参数</van-button>
<!--            <van-button round block @click="toUrl(1)">跳转</van-button>-->
<!--            <van-button round block @click="toUrl(2)">window.location.href</van-button>-->
<!--            <van-button round block @click="toUrl(3)">window.location.replace(url)</van-button>-->
<!--            <van-button round block @click="toUrl(5)">window.open(url,'_blank')</van-button>-->
<!--            <van-button round block @click="toUrl(6)">window.open(url,'_self')</van-button>-->
<!--            <van-button round block @click="toUrl(7)">重定向</van-button>-->
<!--            <van-button round block @click="toUrl(4)">window.navigate(url)【仅支持IE，微信可能无效】</van-button>-->
        </div>
    </div>
</template>

<script>
import {urlEncode} from "../../utils/public";

export default {
    name: "test",
    data(){
        return {
            message:'',
            system:'',
            environment:'',
        }
    },
    mounted() {
        this.message = localStorage.getItem('test_article_url')


        // 判断微信环境
        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            this.environment = "【当前环境】：微信环境"
        }else {
            this.environment = "【当前环境】：其他H5环境"
        }

        // 判断安卓/ios
        if (/(Android)/i.test(navigator.userAgent)) {
            this.system = 'Android';
        } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            this.system = 'IOS';
        } else {
            this.system = '其他系统';
        }


    },
    methods:{
        saveToStorage(){
            localStorage.setItem('test_article_url',this.message)
        },
        toUrl(index){
            localStorage.setItem('test_article_url',this.message)
            switch (index) {
                case 1:
                    document.location.href = this.message
                    break;
                case 2:
                    this.$router.push('/test2?rand='+ Math.round(Math.random()*99999999999999))
                    break
                case 3:
                    this.$router.push('/test3/'+ Math.round(Math.random()*99999999999999))
                    break
                case 4:
                    this.$router.push('/test3/'+ Math.round(Math.random()*99999999999999) + '?rand='+ Math.round(Math.random()*99999999999999))
                    break
            }




            // switch (index) {
            //     case 1:
            //         document.location.href = this.message
            //         break;
            //     case 2:
            //         window.location.href = this.message
            //         break
            //     case 3:
            //         window.location.replace(this.message)
            //         break
            //     case 4:
            //         window.navigate(this.message)
            //         break
            //     case 5:
            //         window.open(this.message,'_blank')
            //         break
            //     case 6:
            //         window.open(this.message,'_self')
            //         break
            //     case 7:
            //         document.location.href = 'http://redirect.chenxiaoxiang.top/?url=' + urlEncode(this.message)
            //         break
            // }
        }
    }
}
</script>

<style scoped>
.test-button-area>button{
    margin-bottom: 10px;
}
</style>
